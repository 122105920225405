import React from 'react';
import { insurance } from '@getpopsure/private-constants';
import {
  setAddOns,
  setQuestionnaire,
  setSickDayPayout,
  setTariffAndDeductible,
} from 'actions';
import Loading from 'components/loading';
import lz from 'lz-string';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  isDeductible,
  isTariff,
  rawQueryParametersAddOnstoAddOn,
} from 'pages/welcome/component/util';
import { validatePath } from './validatePath.utils';
import * as Sentry from '@sentry/browser';
import errorImg from 'components/missingInfoError/img/error.svg';
import Center from 'components/center';

/**
 *
 * Stand alone component for seeding the questionnaire.
 * Will read the search query params to populate a questionnaire with the provided values.
 * Note: If there are previously persisted answers, these will be removed.
 *
 *
 */
export const SeedQuestionnaire = () => {
  // collect the current path and values
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const path = params.get('p');
  const valuesParam = params.get('v');
  const deductible = params.get('deductible');
  const tariff = params.get('tariff');
  const sickDayPayout = params.get('sickDayPayout');
  const addons = params.get('addOns');

  const values = lz.decompressFromEncodedURIComponent(valuesParam ?? '');

  const parsedValues = JSON.parse(values ?? '');

  const dispatch = useDispatch();

  const pathValidationResult = validatePath(path);

  useEffect(() => {
    const addOns = rawQueryParametersAddOnstoAddOn(addons);

    if (isDeductible(deductible) && isTariff(tariff)) {
      dispatch(setTariffAndDeductible(tariff, deductible));
    }

    if (addons !== null && addOns) {
      dispatch(setAddOns(addOns));
    }

    if (sickDayPayout && !isNaN(Number(sickDayPayout))) {
      dispatch(setSickDayPayout(Number(sickDayPayout)));
    }

    // populate the questionnaire with the provided questionnaire answers
    dispatch(setQuestionnaire(parsedValues));

    const timeoutId = setTimeout(() => {
      if (!path) {
        window.location.href = insurance.privateHealth.signup;
      }
      if (pathValidationResult.isValid) {
        window.location.href = pathValidationResult.validPath;
      } else {
        Sentry.captureMessage(
          '[Seeding questionnaire] Invalid path provided for questionnaire seeding.',
          {
            level: 'log',
            tags: {
              feature: 'SEED_QUESTIONNAIRE',
              vertical: 'PRIVATE_HEALTH',
            },
          }
        );
      }
    }, 1000);

    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line
  }, []);

  if (!pathValidationResult.isValid) {
    return (
      <Center>
        <div data-testid="missing-info-error">
          <img src={errorImg} alt="" className="d-block ml-auto mr-auto" />
          <h1 className="p-h2 d-block ta-center mt24 tc-grey-900">
            Some information is missing
          </h1>
          <p className="p-p ta-center tc-grey-600 mt8 w100 wmx6 ml-auto mr-auto">
            Sorry, the link you used is missing some information. Starting over
            from the quote page should fix this issue.
          </p>
          <a
            className="p-btn--primary mt32 wmn3 wmx4 d-block mr-auto ml-auto"
            href={`${insurance.privateHealth.preSignup}/signup/quote`}
          >
            Start over from quote
          </a>
        </div>
      </Center>
    );
  }
  return <Loading />;
};
